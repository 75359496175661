/* eslint-disable */
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import { Service, Stage } from '../components/molecules';
import { DefaultLayout } from '../components/layout';

const pageQuery = graphql`
  {
    allServicesJson {
      edges {
        node {
          title
          catchline
          lead
          body
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 800
                quality: 92
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
    allStagesJson(filter: { siteTitle: { eq: "Leistungen" } }) {
      edges {
        node {
          id
          siteTitle
          siteDescription
          title
          contentBlocks {
            id
            value
          }
          imageSrc {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 800
                quality: 92
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          imageAlt
        }
      }
    }
  }
`;

const Services = ({ location }) => (
  <StaticQuery
    query={pageQuery}
    render={({ allStagesJson, allServicesJson }) => {
      // console.log("🔍 Debugging GraphQL Data:", { allStagesJson, allServicesJson });

      if (!allStagesJson.edges.length) {
        console.error("❌ Error: No stage data found!");
        return null;
      }

      const stageNode = allStagesJson.edges[0].node;
      const { siteTitle, siteDescription, imageSrc, imageAlt, title, contentBlocks } = stageNode;

      // ✅ Get Stage Image (Correctly Processed)
      const stageImage = imageSrc?.childImageSharp
        ? getImage(imageSrc.childImageSharp.gatsbyImageData)
        : imageSrc?.publicURL || null;

      return (
        <DefaultLayout siteTitle={siteTitle} siteDescription={siteDescription} location={location}>
          {/* ✅ Main Stage Section */}
          <Stage
            modifiers={['left-highlighted', 'gradient']}
            image={{
              gatsbyImageData: typeof stageImage === "object" ? stageImage : null,
              src: typeof stageImage === "string" ? stageImage : null,
              alt: imageAlt || "Stage Image",
            }}
            title={<h1 dangerouslySetInnerHTML={{ __html: title }} />}
          >
            {contentBlocks.map(({ id, value }) => (
              <p key={id}>{value}</p>
            ))}
          </Stage>

          {/* ✅ Service List */}
          <div className="container">
            <div className="row">
              {allServicesJson.edges.map(({ node }) => {
                const { title: serviceTitle, catchline, lead, image, body } = node;

                // ✅ Process Service Image Correctly
                const serviceImage = image?.childImageSharp
                  ? getImage(image.childImageSharp.gatsbyImageData)
                  : image?.publicURL || null;

                // console.log(`🔍 Debugging Service Image for: ${serviceTitle}`, serviceImage);

                return (
                  <Service
                    key={serviceTitle}
                    title={serviceTitle}
                    catchline={catchline}
                    lead={lead}
                    image={{
                      gatsbyImageData: typeof serviceImage === "object" ? serviceImage : null,
                      src: typeof serviceImage === "string" ? serviceImage : null,
                      alt: serviceTitle,
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: body }} />
                  </Service>
                );
              })}
            </div>
          </div>
        </DefaultLayout>
      );
    }}
  />
);

export default Services;
